@import '~bootswatch/dist/lux/bootstrap.css';

$dark: #282828;
$light: #ae9f87;
$light-background: #f0ece6;


@import '~bootstrap/scss/bootstrap';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light-background;
  font-family: Futura
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #1a1a1a;
  text-decoration: none;
  transition: 200ms;
  &:hover {
    cursor: pointer;
    color: $gray-700;
  }
}

.banner {
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  isolation: isolate;
  height: 100%;
  background: #282828;
  color: white;
  &::before {  
    content: "";
    background-image: url("/assets/cover.jpeg");
    background-size: cover;
    position: absolute;
    inset: 0;
    opacity: 0.3;
    z-index: -1;
  }
  h1 {
    font-weight: bold !important;
  }
}

.footer{
  background-color: $dark;
  color: $gray-400;
  

  &-fox{
    filter: saturate(0)
    opacity(0.2)
  }

  a {
    color: $gray-400;
    text-decoration: none;
    transition: 200ms;
    &:hover {
      cursor: pointer;
      color: white;
    }
  }
  
}

.card {
  border: 0;
}

.btn-pill{
  border-radius: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.profile-picture{
  width: 15rem;
  position: relative;
  @include media-breakpoint-up(lg) {
    &-bubble{
        border-radius: 50% !important;
        height: 15rem;
        width: 15rem;
        position: absolute;
        top: -7rem;
        border-width: 1rem;
        border-color: $light;
      }
  }
  @include media-breakpoint-down(lg) {
    &-bubble{
       border-width: 0;
    }
  }
}
@include media-breakpoint-down(lg) {
  .profile-picture{
     max-width: 100%;
     max-height: 20rem;
    }
}

.product-images-slider {
  .swiper-slide {
    overflow: hidden;
    img {
      top: 0;
      left: 0;
      min-width: 100%;
    }
  }

  .swiper-button-prev {
    left: 10;
    color: white;
  }

  .swiper-button-next {
    right: 10;
    color: white;
  }
}
.product-images-slider-thumbs {
  .swiper-slide {
    cursor: pointer;
    opacity: 75%;
    transition: 120ms;

    &-thumb-active {
      opacity: 100%;
    }

    &:hover {
      opacity: 100%;
    }
  }

  &-wrapper {
      width: 100%;
      img {
          top: 0;
          left: 0;
          max-width: 100%;
          max-height: 100%;
      }
  }
}